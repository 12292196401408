import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import headerIconLight from "../../assets/headerIconLight.bc7439902d2be2609f06.webp"
import icon from "../../assets/icon.78bb914a4dbcfc43f693.webp"
import { Link } from 'react-router-dom';

function Header({language}) {
  return (
    <Box height="100vh" display="flex" alignItems="center" className="padding">
      
      <Box className="animate__animated animate__slideInLeft" zIndex="10" flex={1} textAlign="left" mt={-17}>
        <Typography component="h1" variant="h2" fontWeight="bold" mb={2}>{language.title}</Typography>
        <Typography component="h5" variant="h5" color="rgb(255,255,255,0.8)">{language.slogan}</Typography>
        <Box mt={3}>
          <Link to="/contact" style={{textDecoration: "none"}}>
            <Button sx={{borderRadius: "0.5rem"}} variant="contained" endIcon={<ArrowForwardIcon />}>{language.buttonLabel}</Button>
          </Link>
        </Box>
      </Box>
      <Box className="animate__animated animate__slideInRight" zIndex="2" flex={1.3} textAlign="right" sx={{display: {xs: "none", lg: "block"}}}>
        <Typography component="img" src={icon} sx={{width: "70%"}}></Typography>
      </Box>
      <Typography component="img" sx={{width: "50%", position: "absolute", zIndex: 0, right: 0, height: "100%", display: {xs: "none", lg: "block"}}} src={headerIconLight}></Typography>
    </Box>
  )
}

export default Header