import * as React from "react";

const SvgFacebook = (props) => (
  <svg
    width={10}
    height={18}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m9.308 7.39-.282 2.368a.447.447 0 0 1-.447.398h-2.46v7.024a.318.318 0 0 1-.314.32H3.3a.318.318 0 0 1-.313-.322L3 10.156H1.137A.447.447 0 0 1 .69 9.71V7.343c0-.248.2-.448.447-.448h1.85V4.6c0-2.655 1.581-4.1 3.892-4.1h1.895c.247 0 .447.2.447.447V2.94c0 .247-.2.448-.447.448H7.613c-1.257.02-1.494.622-1.494 1.52v1.98h2.755a.447.447 0 0 1 .434.501Z"
      fill="#fff"
    />
  </svg>
);

export default SvgFacebook;
