import * as React from "react";

const SvgTwitter = (props) => (
  <svg
    width={18}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.466 2.855a7.177 7.177 0 0 1-1.456 1.6.622.622 0 0 0-.224.485v.056a10.194 10.194 0 0 1-.657 3.547 10.39 10.39 0 0 1-1.935 3.244A9.558 9.558 0 0 1 9 14.558a10.488 10.488 0 0 1-3.297.512c-1.776 0-3.52-.47-5.055-1.362a.29.29 0 0 1-.14-.322.28.28 0 0 1 .274-.21h.425a6.78 6.78 0 0 0 3.789-1.15 3.68 3.68 0 0 1-2.908-2.498.192.192 0 0 1 .224-.249c.203.039.41.058.617.058h.078a3.673 3.673 0 0 1-2.14-3.38.192.192 0 0 1 .284-.167c.262.146.544.255.836.324a3.673 3.673 0 0 1-.592-4.453.192.192 0 0 1 .315-.025 9.618 9.618 0 0 0 6.601 3.527h.018a.139.139 0 0 0 .134-.15 3.89 3.89 0 0 1 .085-1.342 3.559 3.559 0 0 1 2.393-2.558 3.935 3.935 0 0 1 1.161-.18 3.66 3.66 0 0 1 2.425.915c.087.078.2.121.316.121a.48.48 0 0 0 .111-.015 6.777 6.777 0 0 0 1.79-.739.19.19 0 0 1 .28.224 3.63 3.63 0 0 1-.965 1.537 6.571 6.571 0 0 0 1.172-.396.193.193 0 0 1 .235.275Z"
      fill="#fff"
    />
  </svg>
);

export default SvgTwitter;
