import React from 'react'
import {Box, Typography} from "@mui/material"
import langs from "../languages"

import NavBar from '../components/navigation/NavBar'

import Header from '../components/tabs/Header'
import FromStartToFinish from '../components/tabs/FromStartToFinish'
import ProjectPlanning from '../components/tabs/ProjectPlanning'
import UserInterfaceDesign from '../components/tabs/UserInterfaceDesign'
import BackendDevelopment from '../components/tabs/BackendDevelopment'
import FrontendDevelopment from '../components/tabs/FrontendDevelopment'
import DevOps from '../components/tabs/DevOps'
import Footer from '../components/tabs/Footer'

import navBarLight from "../assets/navBarLight.d6ed45367197795f451f.webp"
import headerIconLight from "../assets/headerIconLight.bc7439902d2be2609f06.webp"
import backendDevelopment1 from '../assets/backendDevelopment1.ec124c5c5fb3330851e6.webp'
import backendDevelopment2 from '../assets/backendDevelopment2.7eb7cd023c626465ebe3.webp'
import devOpsLight from "../assets/devOpsLight.4cc508f658cd45b56fb3.webp"


import "animate.css/animate.min.css";
import Aos from "aos";
import "aos/dist/aos.css";
import ResponsiveNavBar from '../components/navigation/ResponsiveNavBar'

function Landing({language}) {
  React.useEffect(() => {
    Aos.init({
      // Global settings:
      disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
      startEvent: 'scroll', // name of the event dispatched on the document, that AOS should initialize on
      initClassName: 'aos-init', // class applied after initialization
      animatedClassName: 'aos-animate', // class applied on animation
      useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
      disableMutationObserver: false, // disables automatic mutations' detections (advanced)
      debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
      throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)
      
    
      // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
      offset: 100, // offset (in px) from the original trigger point
      delay: 0, // values from 0 to 3000, with step 50ms
      duration: 400, // values from 0 to 3000, with step 50ms
      easing: 'ease', // default easing for AOS animations
      once: false, // whether animation should happen only once - while scrolling down
      mirror: true, // whether elements should animate out while scrolling past them
      anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
    
    });
  }, []);

  return (
    <Box>
      <Box zIndex={20}>
        <NavBar language={language}></NavBar>
      </Box>

      <Typography className="light" component="img" src={navBarLight} sx={{top: 0, left: 0, width: {xs: "75%", lg: "50%"}}}></Typography>
      <Typography className="light" component="img" src={headerIconLight} sx={{right: 0, width: "50%", display: {xs: "none", lg: "block"}}}></Typography>

      <Box zIndex={10} id="home">
        <Header language={language.header}></Header>
      </Box>

      <Box zIndex={10} id="from-start-to-finish">
        <FromStartToFinish language={language.fromStartToFinish}></FromStartToFinish>
      </Box>

      <Box zIndex={10} id="project-planning">
        <ProjectPlanning language={language.projectPlanning}></ProjectPlanning>
      </Box>

      <Box zIndex={10} id="ui-design">
        <UserInterfaceDesign language={language.uiDesign}></UserInterfaceDesign>
      </Box>

      <Typography className='light' component="img" src={backendDevelopment1} sx={{right: 0, width: {xs: "100%", lg: "30%"}, marginBottom: "-20rem"}}></Typography>
      <Typography className='light' component="img" src={backendDevelopment2} sx={{left: 0, width: {xs: "100%", lg: "40%"}, marginBottom: "-35rem"}}></Typography>

      <Box zIndex={10} id="backend-development">
        <BackendDevelopment language={language.backendDevelopment}></BackendDevelopment>
      </Box>

      <Box zIndex={10} id="frontend-development">
        <FrontendDevelopment language={language.frontendDevelopment}></FrontendDevelopment>
      </Box>

      <Typography className='light' component="img" src={devOpsLight} sx={{right: 0, marginTop: {xs: "10rem", lg: "-15rem"}, width: {xs: "80%", lg: "30%"}}}></Typography>

      <Box zIndex={10} id="dev-ops">
        <DevOps language={language.devOps}></DevOps>
      </Box>

      <Box zIndex={10} id="footer">
        <Footer language={language.footer}></Footer>
      </Box>
      
      <ResponsiveNavBar></ResponsiveNavBar>
    </Box>
  )
}

export default Landing