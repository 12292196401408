import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import theme from '../../theme'

function FrontendDevelopment({language}) {
  return (
    <Box mt={13} textAlign="center" className="padding">
        <Typography component="h1" variant="h3" fontWeight="bold" mb={3} data-aos="fade-up">{language.title}</Typography>
        <Typography component="p" variant="body1" color={theme.palette.informationText.main} className="subheading" data-aos="fade-up">{language.slogan}</Typography>
    
        <Box display="flex" flexWrap="wrap" justifyContent="space-between" textAlign="left" p={7} borderRadius={8} mt={8} sx={{background: "linear-gradient(49deg, rgba(66,186,255,1) 25%, rgba(240,49,255,1) 100%)"}} data-aos="flip-left">
            {language.cards.map((card) => {
                const animationDelay = (language.cards.indexOf(card) * 150) + ""
                return (
                    <Box sx={{width: {xs: "98%", lg: "30%"}}} mb={3} data-aos="zoom-in-up" data-aos-delay={animationDelay}>
                        {card.icon}
                        <Box>
                            <Typography component="h6" variant="h5" fontWeight="bold" mb={1}>{card.title}</Typography>
                            <Typography component="p" variant="body1">{card.description}</Typography>
                        </Box>
                    </Box>
                )
            })}
        </Box>

    </Box>
  )
}

export default FrontendDevelopment