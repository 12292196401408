import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Typography } from '@mui/material'
import React from 'react'
import theme from '../../theme'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

// Import Assets
import design_plus_backend from "../../assets/design_plus_backend.8e1963ece8193c633055.webp"
import cards_design from "../../assets/cards_design.a930006e556e0255f2e7.webp"
import sneaker_store from "../../assets/sneaker_store.ea69ee2d4a7d2259a890.webp"
import stats_design from "../../assets/stats_design.webp"
import map_design from "../../assets/map_design.1ac3326bf5924b18fe39.webp"
import music_streaming_design from "../../assets/music_streaming_design.aa238a28ab34f9c51508.webp"
import swift_sole_design from "../../assets/swift_sole_design.84f464cc43b18594f942.webp"

var previewDesigns = [
    {
        src: cards_design
    },
    {
        src: sneaker_store
    },
    {
        src: stats_design
    },
    {
        src: map_design
    },
    {
        src: music_streaming_design
    },
    {
        src: swift_sole_design
    },
]

function UserInterfaceDesign({language}) {
    const [expanded, setExpanded] = React.useState(false);

  return (
    <Box bgcolor="#0D1526" textAlign="center" borderRadius={4} zIndex="100" p={5} className="margin">
        <Box>
            <Typography component="h1" variant="h3" fontWeight="bold">{language.title}</Typography>
            <Typography component="p" variant="body1" color={theme.palette.informationText.main} mb={4}>{language.slogan}</Typography>
        </Box>

        <Box display="flex" flexWrap="wrap" justifyContent="center">
            <Box display="flex" flexWrap="wrap" alignItems="space-between" sx={{width: {xs: "98%", lg: "58%"}}}>
                {language.explanationCards.map((card) => {
                    return (
                        <Box display="flex" justifyContent="space-between" alignItems="center" bgcolor="#0B0F1F" mb={3} p={4} borderRadius={7} data-aos={card.onScroll} width="80%">
                            <Box flex={1} textAlign="left">
                                <Typography component="h1" variant="h6" fontWeight="bold" mb={2}>{card.title}</Typography>
                                <Typography component="p" variant="body1" color={theme.palette.informationText.main} mb={4}>{card.description}</Typography>
                            </Box>
                            <Box flex={0.5} sx={{display: {xs: "none", lg: "block"}}}>
                                <Typography component="img" src={card.img} sx={{width: "65%"}}></Typography>
                            </Box>
                        </Box>
                    )
                })}
            </Box>

            <Box sx={{width: {xs: "98%", lg: "34%"}}} textAlign="left" data-aos="zoom-in-left">
                <Box sx={{background: "linear-gradient(150deg, rgba(255,171,10,1) 16%, rgba(255,90,188,1) 100%)"}} p={3} borderRadius={7} width="85%">
                    <Typography component="h1" variant="h6" fontWeight="bold" mb={2}>Design + Backend</Typography>
                    <Typography component="p" variant="body1" mb={4}>{language.designPlusBackend.subtitle}</Typography>
                    <Box>
                        <Typography component="img" src={design_plus_backend} sx={{width: "90%"}}></Typography>
                    </Box>
                </Box>
            </Box>

            <Box>
                <Box display="flex" justifyContent="space-between" flexWrap="wrap" mt={3}>
                    {previewDesigns.map((design) => {
                        return (
                            <Box mb={4} data-aos="flip-down" sx={{width: {xs: "95%", lg: "33.3%"}}}>
                                <Typography width="80%" component="img" src={design.src}></Typography>
                            </Box>
                        )
                    })}
                </Box>
                <Accordion sx={{ background: "transparent", border: "0px"}} expanded={expanded} elevation={0}>
                    <AccordionSummary sx={{border: "0px"}}>
                        <Box width="100%">
                            <Button variant="contained" endIcon={<ArrowForwardIcon />} onClick={e => {expanded ? setExpanded(false) : setExpanded(true)}}>View All Designs</Button>
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box display="flex" justifyContent="space-between" flexWrap="wrap" mt={3}>
                            {previewDesigns.map((design) => {
                                return (
                                    <Box sx={{width: {xs: "95%", lg: "33.3%"}}} mb={4}>
                                        <Typography width="80%" component="img" src={design.src}></Typography>
                                    </Box>
                                )
                            })}
                        </Box>
                    </AccordionDetails>
                </Accordion>
            </Box>

        </Box>

    </Box>
  )
}

export default UserInterfaceDesign