import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: "#3178FF"
    },
    mode: "dark",
    common: {
      main: "#fff"
    },
    blue: {
      main: '#3178FF',
      2: "#009FE3",
      mint: "#4ECDE4"
    },
    background: {
        main: "#101010",
    },
    informationText: {
      main: "#82868A",
    },
    badge: {
      main: "#FF5F98"
    },
    border: {
      main: "#2D294A"
    }
    }
})

export default theme