import { Box, Typography } from '@mui/material'
import theme from "../../theme"
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import React from 'react'

function DevOps({language}) {
  return (
    <Box mt={9} display="flex" justifyContent="space-between" flexWrap="wrap" alignItems="center" className="padding">
        <Box sx={{width: {xs: "100%", lg: "42%"}}} mb={4} data-aos="zoom-in">
            <Typography component="h1" variant="h3" fontWeight="bold" mb={3}>{language.title["1"]} <span style={{color: "#36FF3D"}}>{language.title["2"]}</span> {language.title["3"]} <span style={{color: "#36FF3D"}}>{language.title["4"]}</span> {language.title["5"]}</Typography>
            <Typography component="p" variant="body1" color={theme.palette.informationText.main}>{language.slogan}</Typography>

            <Typography component="p" variant="body1" mt={3} color={theme.palette.informationText.main}>{language.subSlogan}</Typography>
            <Box mt={4}>
                {language.checks.map((check) => {
                    return (
                        <Box display="flex">
                            <Typography color="#36FF3D"><CheckCircleIcon /></Typography>
                            <Typography component="p" variant="body1" color={theme.palette.informationText.main} ml={1}>{check}</Typography>
                        </Box>
                    )
                })}
            </Box>
        </Box>

        <Box zIndex={10} sx={{width: {xs: "100%", lg: "54%"}}} display="flex" justifyContent="space-between" flexWrap="wrap" data-aos="zoom-in">
            {language.cards.map((card) => {
                return (
                    <Box sx={{ cursor: "pointer", border: "0.14rem dashed #27292D", width: {xs: "98%", lg: "41.5%"}, ":hover": {backgroundColor: "rgb(54, 255, 61, 0.05)", border: "0.14rem solid #36FF3D", transition: "800ms"}}} p={2} mb={5} borderRadius={3}>
                        {card.icon}
                        <Typography component="h6" variant="h6" fontWeight="bold">{card.title}</Typography>
                        <Typography component="p" variant="body1">{card.description}</Typography>
                    </Box>
                )
            })}
        </Box>
    </Box>
  )
}

export default DevOps