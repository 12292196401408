import * as React from "react";

const SvgSecurity = (props) => (
  <svg
    width={40}
    height={40}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width={40} height={40} rx={10} fill="#D8D8D8" />
    <rect width={40} height={40} rx={10} fill="url(#Security_svg__a)" />
    <mask
      id="Security_svg__b"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={40}
      height={40}
    >
      <rect width={40} height={40} rx={10} fill="#fff" />
      <rect width={40} height={40} rx={10} stroke="#fff" />
    </mask>
    <g mask="url(#Security_svg__b)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.974 14.01v-.02c-.012-.248-.02-.511-.024-.803a2.738 2.738 0 0 0-2.578-2.683c-2.97-.165-5.268-1.134-7.232-3.048l-.016-.016a1.653 1.653 0 0 0-2.247 0l-.017.016c-1.963 1.914-4.261 2.883-7.232 3.049a2.738 2.738 0 0 0-2.578 2.682c-.004.29-.012.553-.023.803l-.001.046c-.058 3.03-.13 6.799 1.131 10.22.694 1.882 1.744 3.518 3.122 4.862 1.569 1.531 3.624 2.747 6.108 3.613a1.896 1.896 0 0 0 .613.104 1.858 1.858 0 0 0 .615-.105c2.481-.867 4.534-2.083 6.102-3.614 1.377-1.345 2.427-2.981 3.12-4.863 1.267-3.432 1.195-7.209 1.137-10.243ZM20 26.357a6.446 6.446 0 0 1-6.439-6.439A6.446 6.446 0 0 1 20 13.48a6.446 6.446 0 0 1 6.438 6.438A6.446 6.446 0 0 1 20 26.357Zm0-11.361a4.928 4.928 0 0 0-4.923 4.922A4.928 4.928 0 0 0 20 24.84a4.928 4.928 0 0 0 4.922-4.922A4.928 4.928 0 0 0 20 14.996Zm2.922 3.995-3.526 3.526a.756.756 0 0 1-1.072 0l-1.348-1.347a.758.758 0 1 1 1.072-1.072l.812.812 2.99-2.99a.758.758 0 1 1 1.072 1.071Z"
        fill="#fff"
      />
    </g>
    <defs>
      <linearGradient
        id="Security_svg__a"
        x1={-20}
        y1={20}
        x2={20}
        y2={60}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#42BAFF" />
        <stop offset={1} stopColor="#F031FF" />
      </linearGradient>
    </defs>
  </svg>
);

export default SvgSecurity;
