import * as React from "react";

const SvgScale = (props) => (
  <svg
    width={40}
    height={40}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width={40} height={40} rx={10} fill="#D8D8D8" />
    <rect width={40} height={40} rx={10} fill="url(#Scale_svg__a)" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31.297 8H15.359a.703.703 0 0 0-.703.703v15.938c0 .388.315.703.703.703h15.938A.703.703 0 0 0 32 24.64V8.703A.703.703 0 0 0 31.297 8Zm-2.11 6.328a.703.703 0 0 1-1.406 0v-1.115l-7.912 7.912h1.115a.703.703 0 0 1 0 1.406h-2.812a.703.703 0 0 1-.703-.703v-2.812a.703.703 0 0 1 1.406 0v1.115l7.912-7.912h-1.115a.703.703 0 0 1 0-1.406h2.812c.389 0 .703.314.703.703v2.812ZM13.25 24.641c0 1.163.946 2.109 2.11 2.109h4.453v4.547a.703.703 0 0 1-.704.703H8.703A.703.703 0 0 1 8 31.297V20.89c0-.389.315-.703.703-.703h4.547v4.453Z"
      fill="#fff"
    />
    <defs>
      <linearGradient
        id="Scale_svg__a"
        x1={-20}
        y1={20}
        x2={20}
        y2={60}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFAB0A" />
        <stop offset={1} stopColor="#FF5ABC" />
      </linearGradient>
    </defs>
  </svg>
);

export default SvgScale;
