import * as React from "react";

const SvgInstagram = (props) => (
  <svg
    width={18}
    height={18}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.436 17.5a5.069 5.069 0 0 0 5.064-5.064V5.564A5.071 5.071 0 0 0 12.436.5H5.564A5.07 5.07 0 0 0 .5 5.564v6.872A5.071 5.071 0 0 0 5.564 17.5h6.872Zm0-15.289H5.564A3.355 3.355 0 0 0 2.21 5.566v6.87a3.355 3.355 0 0 0 3.355 3.355h6.872a3.355 3.355 0 0 0 3.355-3.355V5.564a3.355 3.355 0 0 0-3.355-3.353ZM9 13.398a4.398 4.398 0 1 1-.005-8.796A4.398 4.398 0 0 1 9 13.398Zm4.422-7.709a1.087 1.087 0 1 1-.004-2.173 1.087 1.087 0 0 1 .004 2.173Z"
      fill="#fff"
    />
  </svg>
);

export default SvgInstagram;
