import * as React from "react";

const SvgSkale = (props) => (
  <svg
    width={299}
    height={50}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask
      id="Skale_svg__c"
      maskUnits="userSpaceOnUse"
      x={248.5}
      y={0}
      width={51}
      height={50}
      fill="#000"
    >
      <path fill="#fff" d="M248.5 0h51v50h-51z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M272.989 1H249.5v23.49h23.489V1ZM297.5 1h-23.489v23.49H297.5V1Zm-48 24.51h23.489V49H249.5V25.51Zm48 0h-23.489V49H297.5V25.51Z"
      />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M272.989 1H249.5v23.49h23.489V1ZM297.5 1h-23.489v23.49H297.5V1Zm-48 24.51h23.489V49H249.5V25.51Zm48 0h-23.489V49H297.5V25.51Z"
      fill="url(#Skale_svg__a)"
      fillOpacity={0.1}
    />
    <path
      d="M249.5 1V0h-1v1h1Zm23.489 0h1V0h-1v1ZM249.5 24.49h-1v1h1v-1Zm23.489 0v1h1v-1h-1ZM274.011 1V0h-1v1h1ZM297.5 1h1V0h-1v1Zm-23.489 23.49h-1v1h1v-1Zm23.489 0v1h1v-1h-1Zm-24.511 1.02h1v-1h-1v1Zm-23.489 0v-1h-1v1h1ZM272.989 49v1h1v-1h-1ZM249.5 49h-1v1h1v-1Zm24.511-23.49v-1h-1v1h1Zm23.489 0h1v-1h-1v1ZM274.011 49h-1v1h1v-1Zm23.489 0v1h1v-1h-1Zm-48-47h23.489V0H249.5v2Zm1 22.49V1h-2v23.49h2Zm22.489-1H249.5v2h23.489v-2Zm-1-22.49v23.49h2V1h-2Zm2.022 1H297.5V0h-23.489v2Zm1 22.49V1h-2v23.49h2Zm22.489-1h-23.489v2H297.5v-2ZM296.5 1v23.49h2V1h-2Zm-23.511 23.51H249.5v2h23.489v-2Zm1 24.49V25.51h-2V49h2ZM249.5 50h23.489v-2H249.5v2Zm-1-24.49V49h2V25.51h-2Zm25.511 1H297.5v-2h-23.489v2Zm1 22.49V25.51h-2V49h2Zm22.489-1h-23.489v2H297.5v-2Zm-1-22.49V49h2V25.51h-2Z"
      fill="url(#Skale_svg__b)"
      mask="url(#Skale_svg__c)"
    />
    <mask
      id="Skale_svg__f"
      maskUnits="userSpaceOnUse"
      x={158.5}
      y={0}
      width={49}
      height={49}
      fill="#000"
    >
      <path fill="#fff" d="M158.5 0h49v49h-49z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M159.5 1h23v23h-23V1Zm24 0h23v23h-23V1Zm11 24h-23v23h23V25Z"
      />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M159.5 1h23v23h-23V1Zm24 0h23v23h-23V1Zm11 24h-23v23h23V25Z"
      fill="url(#Skale_svg__d)"
      fillOpacity={0.1}
    />
    <path
      d="M182.5 1h1V0h-1v1Zm-23 0V0h-1v1h1Zm23 23v1h1v-1h-1Zm-23 0h-1v1h1v-1Zm47-23h1V0h-1v1Zm-23 0V0h-1v1h1Zm23 23v1h1v-1h-1Zm-23 0h-1v1h1v-1Zm-12 1v-1h-1v1h1Zm23 0h1v-1h-1v1Zm-23 23h-1v1h1v-1Zm23 0v1h1v-1h-1Zm-12-48h-23v2h23V0Zm1 24V1h-2v23h2Zm-24 1h23v-2h-23v2Zm-1-24v23h2V1h-2Zm48-1h-23v2h23V0Zm1 24V1h-2v23h2Zm-24 1h23v-2h-23v2Zm-1-24v23h2V1h-2Zm-11 25h23v-2h-23v2Zm1 22V25h-2v23h2Zm22-1h-23v2h23v-2Zm-1-22v23h2V25h-2Z"
      fill="url(#Skale_svg__e)"
      mask="url(#Skale_svg__f)"
    />
    <mask
      id="Skale_svg__i"
      maskUnits="userSpaceOnUse"
      x={68.5}
      y={11.5}
      width={49}
      height={25}
      fill="#000"
    >
      <path fill="#fff" d="M68.5 11.5h49v25h-49z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M92.5 12.5h-23v23h23v-23Zm24 0h-23v23h23v-23Z"
      />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M92.5 12.5h-23v23h23v-23Zm24 0h-23v23h23v-23Z"
      fill="url(#Skale_svg__g)"
      fillOpacity={0.1}
    />
    <path
      d="M69.5 12.5v-1h-1v1h1Zm23 0h1v-1h-1v1Zm-23 23h-1v1h1v-1Zm23 0v1h1v-1h-1Zm1-23v-1h-1v1h1Zm23 0h1v-1h-1v1Zm-23 23h-1v1h1v-1Zm23 0v1h1v-1h-1Zm-47-22h23v-2h-23v2Zm1 22v-23h-2v23h2Zm22-1h-23v2h23v-2Zm-1-22v23h2v-23h-2Zm2 1h23v-2h-23v2Zm1 22v-23h-2v23h2Zm22-1h-23v2h23v-2Zm-1-22v23h2v-23h-2Z"
      fill="url(#Skale_svg__h)"
      mask="url(#Skale_svg__i)"
    />
    <path
      fill="url(#Skale_svg__j)"
      fillOpacity={0.1}
      stroke="url(#Skale_svg__k)"
      d="M.5 11.5H25v24H.5z"
    />
    <path
      d="M132.79 24.79h8.827l-3.856 3.857a.797.797 0 0 0 0 1.122.787.787 0 0 0 1.114 0l5.208-5.208a.787.787 0 0 0 0-1.114l-5.2-5.216a.787.787 0 1 0-1.114 1.114l3.848 3.865h-8.827a.793.793 0 0 0-.79.79c0 .435.356.79.79.79ZM42.79 24.79h8.827l-3.856 3.857a.797.797 0 0 0 0 1.122.787.787 0 0 0 1.114 0l5.208-5.208a.787.787 0 0 0 0-1.114l-5.2-5.216a.787.787 0 1 0-1.114 1.114l3.848 3.865H42.79A.793.793 0 0 0 42 24c0 .435.356.79.79.79ZM221.29 24.79h8.827l-3.856 3.857a.797.797 0 0 0 0 1.122.787.787 0 0 0 1.114 0l5.208-5.208a.787.787 0 0 0 0-1.114l-5.2-5.216a.787.787 0 1 0-1.114 1.114l3.848 3.865h-8.827a.793.793 0 0 0-.79.79c0 .435.356.79.79.79Z"
      fill="#fff"
      fillOpacity={0.75}
    />
    <defs>
      <linearGradient
        id="Skale_svg__a"
        x1={273.5}
        y1={-141.912}
        x2={-12.324}
        y2={-93.912}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C354FF" />
        <stop offset={1} stopColor="#3178FF" />
      </linearGradient>
      <linearGradient
        id="Skale_svg__b"
        x1={273.5}
        y1={-141.912}
        x2={-12.324}
        y2={-93.912}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C354FF" />
        <stop offset={1} stopColor="#3178FF" />
      </linearGradient>
      <linearGradient
        id="Skale_svg__d"
        x1={270.08}
        y1={-142.249}
        x2={-16.418}
        y2={-100.051}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C354FF" />
        <stop offset={1} stopColor="#3178FF" />
      </linearGradient>
      <linearGradient
        id="Skale_svg__e"
        x1={266.599}
        y1={-141.367}
        x2={-18.135}
        y2={-94.367}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C354FF" />
        <stop offset={1} stopColor="#3178FF" />
      </linearGradient>
      <linearGradient
        id="Skale_svg__g"
        x1={235.222}
        y1={-71.503}
        x2={8.554}
        y2={82.013}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C354FF" />
        <stop offset={1} stopColor="#3178FF" />
      </linearGradient>
      <linearGradient
        id="Skale_svg__h"
        x1={233.28}
        y1={-73.99}
        x2={8.398}
        y2={82.131}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C354FF" />
        <stop offset={1} stopColor="#3178FF" />
      </linearGradient>
      <linearGradient
        id="Skale_svg__j"
        x1={125.853}
        y1={-73.086}
        x2={-21.525}
        y2={-26.065}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C354FF" />
        <stop offset={1} stopColor="#3178FF" />
      </linearGradient>
      <linearGradient
        id="Skale_svg__k"
        x1={127.311}
        y1={-73.369}
        x2={-21.4}
        y2={-26.618}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C354FF" />
        <stop offset={1} stopColor="#3178FF" />
      </linearGradient>
    </defs>
  </svg>
);

export default SvgSkale;
