import React from 'react'
import HomeIcon from '@mui/icons-material/Home';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import DataObjectIcon from '@mui/icons-material/DataObject';
import CodeIcon from '@mui/icons-material/Code';
import StorageIcon from '@mui/icons-material/Storage';
import BatteryChargingFullIcon from '@mui/icons-material/BatteryChargingFull';
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from '@mui/material';

const tabs = [
    {
        name: "DevOps",
        link: "#dev-ops",
        icon: <StorageIcon />
    },
    {
        name: "Frontend",
        link: "#frontned-development",
        icon: <CodeIcon />
    },
    {
        name: "Backend",
        link: "#backend-development",
        icon: <DataObjectIcon />
    },
    {
        name: "UI Design",
        link: "#ui-design",
        icon: <DesignServicesIcon />
    },
    {
        name: "Project Planning",
        link: "#project-planning",
        icon: <DocumentScannerIcon />
    },
    {
        name: "How it works",
        link: "#from-start-to-finish",
        icon: <BatteryChargingFullIcon />
    },
    {
        name: "Home",
        link: "#home",
        icon: <HomeIcon />
    }
]

function ResponsiveNavBar() {

  return (
    <SpeedDial ariaLabel="Navigation" sx={{ position: 'fixed', bottom: 16, right: 16, display: {xs: "inline-flex", lg: "none"} }} icon={<SpeedDialIcon />}>
        {tabs.map((tab) => (
            <SpeedDialAction key={tab.name} icon={tab.icon} tooltipTitle={tab.name}/>
        ))}
    </SpeedDial>
  )
}

export default ResponsiveNavBar