import { Box, Typography } from '@mui/material'
import React from 'react'
import theme from "../../theme"

import code from "../../assets/code.bc1474d7fbc74e285ef9.webp"
import SvgLanguages from "../../assets/svg/backendDevelopment/Languages"
import SvgSkale from "../../assets/svg/backendDevelopment/Skale"

const mainCards = [
    {
        title: "Languages We Use",
        description: "A wide variety of programming languages we use are featured in our programmers skillset. C#, Java, Go and Node.JS make up the building of the product. We carefully pick programming languages tailored to your project.",
        icons: <SvgLanguages />
    },
    {
        title: "Scalability Built-In",
        description: "No matter how many people are using the product, the code automatically gives the product the task to make more space for the applications needed to work smoother and easier.",
        icons: <SvgSkale />
    }
]
const subCards = [
    {
        title: "Experience",
        description: "With our 30 years of combined experience, we grant you unique techniques and tactics mastered by no other competition."
    },
    {
        title: "Code Testing",
        description: "Keeping an eye on the code 'blueprints' is essential for better service. By testing our codes, we make sure that all functions and operations work properly. Even when heavily loaded."
    },
    {
        title: "Documented Code",
        description: "We document the code structure understandably and for future causes. Stay flexible with the code by being able to see the cleaner version of it, coming as a form of documentation with the product itself"
    },
]

function BackendDevelopment({language}) {
  return (
    <Box display="flex" alignItems="center" justifyContent="space-between" mt={13} sx={{paddingLeft: {xs: "1.5rem", lg: "0rem"}, paddingRight: {xs: "1.5rem", lg: "5rem"}}}>
        <Box width="32%" zIndex={10} data-aos="fade-right" sx={{display: {xs: "none", lg: "block"}}} position="relative" left={0}>
            <Typography width="100%" component="img" src={code}></Typography>
        </Box>

        <Box zIndex={10} data-aos="fade-up" sx={{width: {xs: "98%", lg: "65%"}}}>
            <Typography component="h1" variant="h3" fontWeight="bold" mb={3}>{language.title}</Typography>
            <Typography component="p" variant="body1" color={theme.palette.informationText.main}>{language.slogan}</Typography>
        
            <Box display="flex" flexWrap="wrap" justifyContent="space-between" mt={3}>
                {language.mainCards.map((card) => {
                    const animationDelay = (mainCards.indexOf(card) * 100) + "";
                    return (
                        <Box sx={{width: {xs: "98%", lg: "42%"}}} bgcolor="rgb(255,255,255,0.05)" p={3} mb={3} borderRadius={4} data-aos="zoom-in" data-aos-delay={animationDelay}>
                            <Box mb={3}>
                                {card.icons}
                            </Box>
                            <Box>
                                <Typography component="h6" variant="h5" fontWeight="bold" mb={1}>{card.title}</Typography>
                                <Typography component="p" variant="body1" color={theme.palette.informationText.main}>{card.description}</Typography>
                            </Box>
                        </Box>
                    )
                })}
            </Box>

            <Box display="flex" flexWrap="wrap" justifyContent="space-between" mt={3}>
                {language.subCards.map((card) => {
                    const animationDelay = (subCards.indexOf(card) * 100) + "";
                    return (
                        <Box sx={{width: {xs: "98%", lg: "26%"}}} bgcolor="rgb(255,255,255,0.05)" p={3} borderRadius={4} mb={3} data-aos="fade-in-up" data-aos-delay={animationDelay}>
                            <Typography component="h6" variant="h5" fontWeight="bold" mb={1}>{card.title}</Typography>
                            <Typography component="p" variant="body1" color={theme.palette.informationText.main}>{card.description}</Typography>
                        </Box>
                    )
                })}
            </Box>

        </Box>
    </Box>
  )
}

export default BackendDevelopment