import { Alert, Autocomplete, Box, Button, Snackbar, TextField, Typography, Checkbox, FormControlLabel } from '@mui/material'
import React from "react";

import backendDevelopment1 from "../assets/backendDevelopment1.ec124c5c5fb3330851e6.webp";
import backendDevelopment2 from "../assets/backendDevelopment2.7eb7cd023c626465ebe3.webp";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Link } from "react-router-dom";

const services = [
  "Landing page",
  "User Interface Design",
  "Backend Development",
  "Frontend Development",
  "Frontend + Backend Development",
  "Dev Ops",
  "All in one",
  "Enterprise Solution",
  "App Development",
];
const budgets = [
  "1000kr - 5000kr",
  "10.000kr - 25.000kr",
  "25.000kr - 50.000kr",
  "50.000kr - 100.000kr",
  "100.000kr+",
];

function Contact({ language }) {
  var defaultAlert = {
    open: false,
    type: "info",
    message: "",
  };
  const [alert, setAlert] = React.useState(defaultAlert);

  const [fullName, setFullName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [service, setService] = React.useState("");
  const [budget, setBudget] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [gdprConsent, setGdprConsent] = React.useState(false);
  const [fullNameError, setFullNameError] = React.useState(false);
  const [emailError, setEmailError] = React.useState(false);
  const [serviceError, setServiceError] = React.useState(false);
  const [budgetError, setBudgetError] = React.useState(false);
  const [messageError, setMessageError] = React.useState(false);

  const onSend = async () => {
    if (fullName !== "") {
      setFullNameError(false);
    }
    if (email !== "") {
      setEmailError(false);
    }
    if (service !== "") {
      setServiceError(false);
    }
    if (budget !== "") {
      setBudgetError(false);
    }
    if (message !== "") {
      setMessageError(false);
    }
    if (!gdprConsent) {
      setAlert({open: true, type: "error", message: "Du måste ge ditt samtycke till våra dataskyddsbestämmelser innan du fortsätter."});
      return;
    }
    if (
      fullName === "" ||
      email === "" ||
      service === "" ||
      budget === "" ||
      message === ""
    ) {
      if (fullName === "") {
        setFullNameError(true);
      }
      if (email === "") {
        setEmailError(true);
      }
      if (service === "") {
        setServiceError(true);
      }
      if (budget === "") {
        setBudgetError(true);
      }
      if (message === "") {
        setMessageError(true);
      }
      return;
    }
    var form = {
      fullName: fullName,
      email: email,
      service: service,
      budget: budget,
      message: message,
    };

    const rawResponse = await fetch(
      "https://api.vanquish-software.com/api/contacts",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(form),
      }
    );
    const parsedResponse = await rawResponse.json();

    setFullName("");
    setFullNameError(false);
    setEmail("");
    setEmailError(false);
    setService("");
    setServiceError(false);
    setBudget("");
    setBudgetError(false);
    setMessage("");
    setMessageError(false);
    setGdprConsent(false);
    setAlert({
      open: true,
      type: parsedResponse.type,
      message: parsedResponse.message,
    });
  };

  return (
    <Box>
      <Box
        pt={2}
        className="animate__animated animate__slideInDown"
        display="flex"
        justifyContent="center"
        alignItems="center"
      ></Box>

      <Typography
        className="light"
        component="img"
        src={backendDevelopment1}
        sx={{ right: 0, width: { xs: "100%", lg: "30%" }, top: 10 }}
      ></Typography>
      <Typography
        className="light"
        component="img"
        src={backendDevelopment2}
        sx={{
          left: 0,
          width: { xs: "90%", lg: "24%" },
          top: { xs: -80, lg: -150 },
        }}
      ></Typography>

      <Box
        className="animate__animated animate__slideInUp"
        mt={5}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Box sx={{ width: { xs: "97%", lg: "55%" } }}>
          <Box textAlign="center">
            <Typography component="h2" variant="h3" fontWeight="bold">
              {language.title}
            </Typography>
            <Typography
              component="h6"
              variant="h6"
              fontWeight="bold"
              color="rgb(255,255,255,0.8)"
            >
              {language.slogan}
            </Typography>
          </Box>

          <Box
            mt={4}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            flexWrap="wrap"
          >
            <Box sx={{ width: { xs: "100%", lg: "49%" } }} mb={2}>
              <TextField
                fullWidth
                variant="outlined"
                label={language.labels.fullName}
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                error={fullNameError}
              ></TextField>
            </Box>
            <Box sx={{ width: { xs: "100%", lg: "49%" } }} mb={2}>
              <TextField
                fullWidth
                variant="outlined"
                label={language.labels.eMail}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                error={emailError}
              ></TextField>
            </Box>
            <Box sx={{ width: { xs: "100%", lg: "49%" } }} mb={2}>
              <Autocomplete
                width="100%"
                onChange={(event, value) => setService(value)}
                value={service}
                options={services}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={language.labels.service}
                    error={serviceError}
                    onChange={(event, value) => setService(value)}
                    value={service}
                  />
                )}
              />
            </Box>
            <Box sx={{ width: { xs: "100%", lg: "49%" } }} mb={2}>
              <Autocomplete
                width="100%"
                onChange={(event, value) => setBudget(value)}
                value={budget}
                options={budgets}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={language.labels.budget}
                    error={budgetError}
                    onChange={(event, value) => setBudget(value)}
                    value={budget}
                  />
                )}
              />
            </Box>
            <Box width="100%" mb={3}>
              <TextField
                fullWidth
                label={language.labels.message}
                multiline
                rows={12}
                onChange={(e) => setMessage(e.target.value)}
                value={message}
                error={messageError}
              />
            </Box>

            <Box mb={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={gdprConsent}
                    onChange={(e) => setGdprConsent(e.target.checked)}
                    name="gdprConsent"
                    color="primary"
                  />
                }
                label="Jag godkänner att mina uppgifter sparas enligt dataskyddsbestämmelserna. Mina uppgifter kommer inte att användas för marknadsföringssyfte."
              />
            </Box>

            <Box
              width="100%"
              display="flex"
              justifyContent="center"
              mt={2}
              mb={2}
            >
              <Button
                variant="contained"
                endIcon={<ArrowForwardIcon />}
                onClick={(e) => onSend()}
              >
                {language.sendButtonLabel}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>

      <Snackbar
        open={alert.open}
        autoHideDuration={10000}
        onClose={(e) => setAlert(defaultAlert)}
      >
        <Alert
          onClose={(e) => setAlert(defaultAlert)}
          severity={alert.type}
          sx={{ width: "100%" }}
        >
          {alert.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default Contact;
